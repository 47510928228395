import { useState, useEffect, ChangeEvent } from 'react';
import { Select } from '@headlessui/react';
import { useAppSelector } from '../../hooks/reduxHooks';
import OrangeButton from '../../components/Button/OrangeButton';
import usersIcon from '../../assets/icons/users.svg';
import boxIcon from '../../assets/icons/box.svg';
import dollarIcon from '../../assets/icons/dollarcircle.svg';
import qrcodeIcon from '../../assets/icons/qrcode.svg';
import QRPopup from '../../components/Dialog/QRPopup';
import { fetchRetailerPerformance } from '../../services/retailer';

const Dashboard = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [performance, setPerformance] = useState([]);
  const [dateOptions, setDateOptions]: any[] = useState([]);
  const [dateFilter, setDateFilter] = useState('all');
  const user = useAppSelector((state) => state.user.user);
  // can change to usecontext but
  const name = user?.name || '';
  const memberRegistered = performance.length;
  const activeMembers = performance.filter(
    (user: { [key: string]: any }) =>
      user.receipts.length > 0 &&
      user.receipts.filter((receipt: { status: string }) => receipt.status === 'APPROVED').length > 0
  ).length;
  const productSold = performance
    .filter(
      (user: { [key: string]: any }) =>
        user.receipts.length > 0 &&
        user.receipts.filter((receipt: { status: string }) => receipt.status === 'APPROVED').length > 0
    )
    .map((user: { [key: string]: any }) =>
      user.receipts.find((receipt: { status: string }) => receipt.status === 'APPROVED')
    )
    .filter((receipt) => receipt.items && receipt.items.length)
    .map((receipt) => receipt.items)
    .flat()
    .reduce((previous, item) => previous + parseInt(item.quantity), 0);
  const incentiveEarned = activeMembers * 5;
  const retailerId = user?.retailerIds[0] || 'error';
  const qrcode = `${process.env.REACT_APP_LOYALTY_URL}/landing?retailerId=${retailerId}`;

  useEffect(() => {
    if (user && retailerId && retailerId !== 'error') {
      const fetchPerformance = async () => {
        try {
          let queryString = `?retailerId=${retailerId}&includes=receipt`;

          if (dateFilter !== 'all') {
            const split = dateFilter.split('-');
            const year = parseInt(split[0]);
            const startMonth = parseInt(split[1]) - 1;
            const endMonth = parseInt(split[1]);

            const startDate = new Date(new Date(year, startMonth, 1).setHours(0, 0, 0, 0));
            const endDate = new Date(new Date(year, endMonth, -1).setHours(23, 59, 59, 999));

            queryString += `&startDate=${startDate}&endDate=${endDate}`;
          }

          const data = await fetchRetailerPerformance(queryString);
          setPerformance(data.data);
        } catch (error) {
          console.log(error);
        }
      };
      fetchPerformance();
    }
  }, [user, retailerId, dateFilter]);

  useEffect(() => {
    if (user) {
      let startDate: Date = new Date(new Date(user.createdAt).setDate(1));
      // let startDate = new Date(2023, 0, 1);
      const now = new Date();

      let startString: string = '';
      const endString = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}`;

      const dates: any[] = [];

      while (startString !== endString) {
        const year = startDate.getFullYear();
        const month = (startDate.getMonth() + 1).toString().padStart(2, '0');

        const value = `${year}-${month}`;
        dates.push({ label: value, value });
        startString = value;
        startDate = new Date(startDate.setMonth(startDate.getMonth() + 1));
      }

      setDateOptions(dates);
    }
  }, [user]);

  const handleFilter = (e: ChangeEvent<HTMLSelectElement>) => {
    setDateFilter(e.target.value);
  };

  return (
    <main id="dashboard" className="font-montserrat my-3 px-8">
      <section>
        <p className="text-xl font-semibold">Hi, {name}</p>
        <p className="text-sm font-semibold">New User Reward: +RM5 TnG PIN</p>
      </section>

      {/* can split into components but whatever, no time */}
      <section className="my-5">
        <div className="rounded-xl bg-white p-3">
          <div className="flex justify-between">
            <p className="font-bauhaus text-2xl font-bold">My Performance</p>
            {/* <p className="orange-bg font-sofia-pro self-center rounded-md px-2 text-sm text-white">All</p> */}
            <Select
              name="date"
              aria-label="date"
              value={dateFilter}
              onChange={handleFilter}
              className="orange-bg font-sofia-pro w-24 rounded-md pl-8 pt-1 text-sm font-bold text-white"
            >
              <option value="all">All</option>
              {dateOptions.map((data: { value: string; label: string }, idx: number) => (
                <option key={idx} value={data.value}>
                  {data.label}
                </option>
              ))}
            </Select>
          </div>

          <div className="mt-3 grid grid-cols-2 gap-2 text-xs font-medium">
            <div className="px-3">
              <p>
                Member
                <br />
                Registered
              </p>
              <div className="flex">
                <img src={usersIcon} alt="users" />
                <p className="ml-2 text-xl font-black">{memberRegistered}</p>
              </div>
            </div>

            <div className="px-3">
              <p>
                No. of
                <br />
                Active Member
              </p>
              <div className="flex">
                <img src={usersIcon} alt="users" />
                <p className="ml-2 text-xl font-black">{activeMembers}</p>
              </div>
            </div>

            <div className="px-3">
              <p>
                Number of
                <br />
                Product Sold
              </p>
              <div className="flex">
                <img src={boxIcon} alt="box" />
                <p className="ml-2 text-xl font-black">{productSold}</p>
              </div>
            </div>

            <div className="px-3">
              <p>
                Incentive
                <br />
                Earned
              </p>
              <div className="flex">
                <img src={dollarIcon} alt="dollar" />
                <p className="ml-2 text-xl font-black">RM{incentiveEarned}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <OrangeButton
        id="dashboard-qr-btn"
        btnText={
          <div className="flex items-center justify-center py-4">
            <p className="font-bauhaus m-0 p-0 text-2xl">Invite Shopper to Join</p>
            <img src={qrcodeIcon} alt="qrcode" className="ml-3" />
          </div>
        }
        onClick={() => setIsOpen(true)}
      />

      <QRPopup config={{ isOpen, title: qrcode, onClose: () => setIsOpen(false) }} />
    </main>
  );
};
export default Dashboard;
